<template>
  <div>
    <b-sidebar
      id="sidebar-Department"
      bg-variant="white"
      v-model="visibility1"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility1 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Building name"
              invalid-feedback="Building name is required."
            >
              <b-form-input
                ref="name"
                id="mc-first-name"
                placeholder="Enter building name"
                v-model="myObj.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Year Built"
              invalid-feedback="Year Built is required."
            >
              <b-form-input
                ref="year"
                type="number"
                placeholder="Enter year"
                v-model="myObj.yearBuilt"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Total Floors"
              invalid-feedback="Total Floors is required."
            >
              <b-form-input
                ref="year"
                type="number"
                placeholder="Enter no. of floors"
                v-model="myObj.totalFloors"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="addBuild()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Department"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
      width="600px"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Room</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1" v-for="(item, ind) in roomObj" :key="ind">
          <b-col md="2" class="pr-0">
            <b-form-group
              label="Room No."
              invalid-feedback="Room No. is required."
            >
              <b-form-input
                type="number"
                placeholder="Enter room no."
                v-model="item.roomNo"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" class="pr-0">
            <b-form-group label="Floor" invalid-feedback="Floor is required.">
              <b-form-input
                type="number"
                placeholder="Enter floor no."
                v-model="item.floor"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" class="pr-0">
            <b-form-group
              label="Capacity"
              invalid-feedback="Capacity is required."
            >
              <b-form-input
                type="number"
                placeholder="Enter capacity"
                v-model="item.capacity"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" class="pr-0">
            <b-form-group label="Type" invalid-feedback="Type is required.">
              <v-select
                v-model="item.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="select type"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" class="mt-2 pr-0">
            <b-button
              v-if="ind == roomObj.length - 1"
              variant="primary"
              class="btn-icon rounded-circle"
              @click="addMore()"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="roomObj.length > 1"
              variant="outline-danger"
              class="btn-icon rounded-circle ml-50"
              @click="remove(item, ind)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="request"
              block
              @click="saveRoom()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Department"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Equipments</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="3">
            Room no:
            <br />
            Type:
            <br />
            Capacity:
          </b-col>
          <b-col md="3">
            <b-badge variant="light-primary">
              {{ currentRoom.roomNo }}
            </b-badge>
            <br />
            <b-badge variant="light-primary">
              {{ currentRoom.type }}
            </b-badge>
            <br />
            <b-badge variant="light-primary">
              {{ currentRoom.capacity }}
            </b-badge>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mt-1" v-for="(item, ind) in currentEquips" :key="ind">
          <b-col md="5" class="pr-0">
            <b-form-group
              label="Equipment Name"
              invalid-feedback="Name is required."
            >
              <b-form-input placeholder="Enter name" v-model="item.name" />
            </b-form-group>
          </b-col>
          <b-col md="4" class="pr-0">
            <b-form-group
              label="Quantity"
              invalid-feedback="Quantity is required."
            >
              <b-form-input
                type="number"
                placeholder="Enter quantity"
                v-model="item.quantity"
              />
            </b-form-group>
          </b-col>

          <b-col md="3" class="mt-2 pr-0">
            <b-button
              v-if="ind == currentEquips.length - 1"
              variant="primary"
              class="btn-icon rounded-circle"
              @click="addEq()"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="currentEquips.length > 1"
              variant="outline-danger"
              class="btn-icon rounded-circle ml-50"
              @click="removeEq(item, ind)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="request"
              block
              @click="saveEq()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row align-h="end" class="text-right">
        <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
          <b-dropdown
            style="margin-inline: 2px; margin-bottom: 2px"
            v-for="(d, ind) in buildings"
            :key="ind"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            :text="d.name"
            :variant="current == d.id ? 'primary' : 'outline-primary'"
            right
            @click="LoadRooms(d.id)"
          >
            <b-dropdown-item @click="openBuild(d)"> Edit </b-dropdown-item>
            <b-dropdown-item @click="Delete(d.id)"> Delete </b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
        </b-col>
        <b-col
          class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          style="margin-right: 8px"
          cols="*"
        >
          <b-button
            @click="openBuild(0)"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="openRoom()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Room</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-center"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="mb-0 w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <!-- <b-button
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon ml-50"
            :disabled="exporting"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small varaint="primary" />
            <feather-icon v-else icon="UploadIcon" class="" />
          </b-button> -->
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(roomNo)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(type)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(capacity)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(floor)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="ml-2">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Equipments"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="openEquip(data.item)"
            >
              <feather-icon icon="LayersIcon" class="" />
            </b-button>

            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.left
              title="Print"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
            >
              <feather-icon icon="PrinterIcon" class="" />
            </b-button> -->

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="deleteRoom(data.item)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      request: false,
      dataLoading: false,
      exporting: false,
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      visibility: false,
      visibility1: false,
      fields: [
        { label: "roomNo", key: "roomNo" },
        { label: "floor", key: "floor" },
        { label: "type", key: "type" },
        { label: "capacity", key: "capacity" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      buildings: [
        {
          id: 1,
          name: "Block A",
          yearBuilt: 2020,
          totalFloors: 3,
          campusID: this.$store.state.userData.cId,
          rooms: [
            {
              id: 1,
              buildingID: 1,
              roomNo: 101,
              floor: 1,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 2,
              buildingID: 1,
              roomNo: 102,
              floor: 1,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 3,
              buildingID: 1,
              roomNo: 103,
              floor: 1,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 7,
              buildingID: 1,
              roomNo: 104,
              floor: 1,
              type: "office",
              capacity: 15,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 4,
              buildingID: 1,
              roomNo: 201,
              floor: 2,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 5,
              buildingID: 1,
              roomNo: 202,
              floor: 2,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 6,
              buildingID: 1,
              roomNo: 203,
              floor: 2,
              type: "lab",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
          ],
        },
        {
          id: 2,
          name: "Block B",
          yearBuilt: 2020,
          totalFloors: 1,
          campusID: this.$store.state.userData.cId,
          rooms: [
            {
              id: 8,
              buildingID: 2,
              roomNo: 101,
              floor: 1,
              type: "office",
              capacity: 20,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 9,
              buildingID: 2,
              roomNo: 102,
              floor: 1,
              type: "office",
              capacity: 20,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 10,
              buildingID: 2,
              roomNo: 103,
              floor: 1,
              type: "lab",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 11,
              buildingID: 2,
              roomNo: 201,
              floor: 2,
              type: "classroom",
              capacity: 20,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 12,
              buildingID: 2,
              roomNo: 202,
              floor: 2,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 13,
              buildingID: 2,
              roomNo: 203,
              floor: 2,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 14,
              buildingID: 2,
              roomNo: 204,
              floor: 2,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 15,
              buildingID: 2,
              roomNo: 205,
              floor: 2,
              type: "classroom",
              capacity: 30,
              campusID: this.$store.state.userData.cId,
            },
          ],
        },
      ],
      roomObj: [],
      current: 1,
      myObj: {},
      typeOptions: [
        { text: "Classroom", value: "classroom" },
        { text: "Lab", value: "lab" },
        { text: "Office", value: "office" },
      ],
      equips: [
        {
          id: 1,
          roomID: 1,
          name: "Tables",
          quantity: 5,
        },
        {
          id: 2,
          roomID: 1,
          name: "Chairs",
          quantity: 30,
        },
        {
          id: 3,
          roomID: 2,
          name: "Tables",
          quantity: 5,
        },
        {
          id: 4,
          roomID: 2,
          name: "Chairs",
          quantity: 30,
        },
        {
          id: 5,
          roomID: 8,
          name: "Tables",
          quantity: 7,
        },
        {
          id: 6,
          roomID: 8,
          name: "Chairs",
          quantity: 26,
        },
      ],
      visibility2: false,
      currentEquips: [],
      currentRoom: {},
    };
  },
  computed: {
    filters() {
      return this.items.filter((pro) => {
        return pro.roomNo
          .toString()
          .toLowerCase()
          .match(this.searchQuery.toLowerCase());
      });
    },
  },
  created() {
    this.LoadRooms(this.current);
    // this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openEquip(item) {
      this.currentRoom = item;
      this.currentEquips = this.equips.filter((el) => el.roomID == item.id);
      if (this.currentEquips.length == 0) {
        this.currentEquips = [
          {
            id: 0,
            roomID: item.id,
            name: "",
            quantity: 0,
          },
        ];
      }
      this.visibility2 = true;
      // console.log(this.currentEquips);
    },
    addEq() {
      this.currentEquips.push({
        id: 0,
        roomID: this.currentRoom.id,
        name: "",
        quantity: 0,
      });
      // console.log(this.currentEquips);
    },
    removeEq(item, ind) {
      this.currentEquips.splice(ind, 1);
      if (item.id != 0) {
        this.equips = this.equips.filter((el) => el.id != item.id);
      }
    },
    saveEq() {
      this.request = true;
      this.currentEquips.forEach((el) => {
        let obj = this.equips.find((elem) => elem.id == el.id);
        if (obj) {
          let ind = this.equips.findIndex((elem) => elem.id == el.id);
          this.equips[ind] = el;
        } else {
          el.id = Math.max(...this.equips.map((obj) => obj.id), 0) + 1;
          this.equips.push(el);
        }
      });
      this.request = false;
      this.visibility2 = false;
      this.$bvToast.toast("Equipments saved successfully!", {
        title: "Success!",
        variant: "success",
        toaster: "b-toaster-top-right",
        solid: true,
      });
    },

    openRoom() {
      if (this.items.length == 0) {
        this.roomObj = [
          {
            id: 0,
            buildingID: this.current,
            roomNo: 0,
            floor: 1,
            type: "classroom",
            capacity: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else {
        this.roomObj = this.items.map((obj) => ({ ...obj }));
      }
      this.visibility = true;
    },
    addMore() {
      this.roomObj.push({
        id: 0,
        buildingID: this.current,
        roomNo: 0,
        floor: 1,
        type: "classroom",
        capacity: 0,
        campusID: this.$store.state.userData.cId,
      });
    },
    remove(item, ind) {
      this.roomObj.splice(ind, 1);
    },
    saveRoom() {
      this.request = true;
      let obj = this.buildings.findIndex((el) => el.id == this.current);
      this.buildings[obj].rooms = [...this.roomObj];
      this.request = false;
      this.visibility = false;
      this.LoadRooms(this.current);
      this.$bvToast.toast("Rooms saved successfully!", {
        title: "Success!",
        variant: "success",
        toaster: "b-toaster-top-right",
        solid: true,
      });
    },
    LoadRooms(id) {
      this.current = id;
      this.items = this.buildings.find((el) => el.id == id).rooms;
    },
    async deleteRoom(item) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        let obj = this.buildings.find((el) => el.id == item.buildingID);
        if (obj) {
          obj.rooms = obj.rooms.filter((el) => el.id != item.id);
        }
        this.LoadRooms(this.current);
        this.$bvToast.toast("Room deleted!", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      }
    },

    openBuild(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          name: "",
          yearBuilt: 2023,
          totalFloors: 1,
          campusID: this.$store.state.userData.cId,
          rooms: [],
        };
        this.sidebarTitle = "Add Building";
        this.sidebarButton = "Save";
      } else {
        this.myObj = { ...id };
        this.sidebarTitle = "Update Building";
        this.sidebarButton = "Update";
      }
      this.visibility1 = true;
    },
    addBuild() {
      this.request = true;
      if (this.myObj.id == 0) {
        this.myObj.id =
          this.buildings.length == 0 ? 1 : this.buildings.at(-1).id + 1;
        this.buildings.push(this.myObj);
        this.$bvToast.toast("Building added successfully!", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      } else {
        let obj = this.buildings.findIndex((el) => el.id == this.myObj.id);
        // console.log(obj);
        this.buildings[obj] = this.myObj;
        this.$bvToast.toast("Building updated successfully!", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      }
      this.request = false;
      this.visibility1 = false;
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        this.buildings = this.buildings.filter((el) => el.id != id);
        if (this.buildings.length == 0) {
          this.current = 0;
          this.items = [];
        } else {
          this.LoadRooms(this.buildings[0].id);
        }
        this.$bvToast.toast("Building deleted!", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "DatesheetGrid/Load?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      this.dataLoading = false;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
